import NextI18Next from 'next-i18next'
import path from 'path'

const NextI18NextInstance = new NextI18Next({
  otherLanguages: ['tc', 'en'],
  defaultLanguage: 'tc',
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  strictMode: false,
  localePath: path.resolve('./public/locales'),
  detection: {
    order: ['querystring', 'cookie', 'header']
  },
  shallowRender: true
})

export const { appWithTranslation, withTranslation, i18n, Trans, useTranslation, Router } =
  NextI18NextInstance

i18n.init({
  interpolation: {
    format: function (value, format) {
      switch (format) {
        case 'numbers':
          return new Intl.NumberFormat().format(Number(value))
        default:
          return value
      }
    }
  }
})
